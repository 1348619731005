import { TitleContext } from 'contexts/TitleContext'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'

// Filtro personalizado para rango de fechas
export default function DateRangeColumnFilter({ column }) {
  const { filterValue, setFilter } = column
  const previousFilter = localStorage.getItem(column.id)
  const { title } = useContext(TitleContext)

  useEffect(() => {
    localStorage.setItem(column.id, JSON.stringify(filterValue))
  }, [previousFilter, filterValue])

  useEffect(() => {
    if (
      previousFilter !== 'undefined' &&
      previousFilter &&
      previousFilter.trim() !== ''
    ) {
      if (previousFilter.includes('[')) {
        setFilter(JSON.parse(previousFilter))
      } else {
        setFilter(
          previousFilter.split(',').filter((item) => {
            return item.trim() === '' ? ' ' : item
          })
        )
      }
    }
  }, [title])

  return (
    <div className="flex flex-col space-y-1">
      <input
        type="date"
        className={`w-full py-1 pr-1 border-2 border-gray-200 cursor-pointer focus:outline-none hover:border-gray-300 font-noto font-normal rounded-sm px-1 text-gray-400 text-sm`}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setFilter((old = []) => [e.target.value, old[1]])}
        value={filterValue ? filterValue[0] : ''}
      />

      <input
        type="date"
        className={`w-full py-1 pr-1 border-2 border-gray-200 cursor-pointer focus:outline-none hover:border-gray-300 font-noto font-normal rounded-sm px-1 text-gray-400 text-sm`}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setFilter((old = []) => [old[0], e.target.value])}
        value={filterValue ? filterValue[1] : ''}
      />
    </div>
  )
}

DateRangeColumnFilter.propTypes = {
  column: PropTypes.object.isRequired
}
