// PropTypes + HOOKS
import PropTypes from 'prop-types'
import { Link } from 'wouter'
import { useTranslation } from 'react-i18next';

export default function SubMenuItems({ submenu, location }) {
  const { t } = useTranslation();
  return (
    <li key={submenu.slug}>
      <Link href={submenu.slug}>
        <div
          // className={`pl-6 flex items-center justify-between pr-2 py-2 rounded-sm group ${
          //   submenu.slug === location
          //     ? 'underline decoration-[#00c8bb] text-[#00c8bb]'
          //     : submenu.active &&
          //       'text-[#00c8bb] no-underline hover:underline decoration-[#ffffff] hover:text-white'
          // }`}
          className={`ml-4 flex items-center justify-between px-2 py-1 group border-s-2 text-sm ${
            submenu.slug === location
              ? 'text-[#00c8bb] border-[#00c8bb]'
              : submenu.active &&
                'border-transparent border-white text-white hover:border-[#00c8bb] hover:text-[#00c8bb] focus:outline-none focus:border-[#00c8bb] focus:text-[#00c8bb]'
          }`}
          // h-10 px-4 font-medium text-[#0b1f92] hover:text-[#00c8bb] transition-colors duration-150 outline-none underline decoration-2 decoration-[#00c8bb] hover:decoration-[#0b1f92]
        >
          <span className="text-lg uppercase font-notoExtraCondensedBlack ms-1">
            {submenu.title}
          </span>
        </div>
      </Link>
    </li>
  )
}

SubMenuItems.propTypes = {
  submenu: PropTypes.object,
  user: PropTypes.object,
  slug: PropTypes.string,
  location: PropTypes.any
}
