import ColumnFilter from 'components/ui/Table/Filters/ColumnFilter'
import DateRangeColumnFilter from 'components/ui/Table/Filters/DateRangeColumnFilter'
import Table from 'components/ui/Table/Table'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function OrdersCompGrid({ items, handleClick }) {
  const [tableData, setTableData] = useState([])
  const { t, i18n } = useTranslation();

  function formatDate(dateString) {
    // Obtener los componentes de la fecha
    const date = new Date(dateString)
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()

    // Construir la cadena de fecha formateada
    return `${day}/${month}/${year}`
  }

  // Columnas:
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <>
            {t('list_table.num_order')}
            <br />
            Cliente
          </>
        ),
        TitleDescription: t('list_table.num_order')+' Cliente',
        accessor: 'Orders_Numero_Pedido_Cliente',
        Filter: ColumnFilter
      },
      {
        Header: () => (
          <>
            {t('list_table.num_order')}
            <br />
            Techno
          </>
        ),
        TitleDescription: t('list_table.num_order')+' Technoform',
        accessor: 'Orders_Numero_Pedido_Technoform',
        Filter: ColumnFilter
      },
      {
        Header: () => (
          <>
            {t('list_table.position')}
          </>
        ),
        TitleDescription: t('list_table.position'),
        accessor: 'Orders_Posicion'
      },
      {
        Header: () => (
          <>
            {t('list_table.reference')}
            <br />
            Cliente
          </>
        ),
        TitleDescription: t('list_table.reference')+' Cliente',
        accessor: 'Orders_Referencia_Cliente',
        Filter: ColumnFilter
      },
      {
        Header: () => (
          <>
            {t('list_table.reference')}
            <br />
            Technoform
          </>
        ),
        TitleDescription: t('list_table.reference')+' Technoform',
        accessor: 'Orders_Referencia_Technoform',
        Filter: ColumnFilter
      },
      {
        Header: () => (
          <>
            {t('list_table.confection')}
          </>
        ),
        TitleDescription: t('list_table.confection'),
        accessor: 'Orders_Confeccion'
      },
      {
        Header: () => (
          <>
            {t('list_table.material')}
          </>
        ),
        TitleDescription: t('list_table.material'),
        accessor: 'Orders_Material'
      },
      {
        Header: () => (
          <>
            {t('list_table.meters')}
          </>
        ),
        TitleDescription: t('list_table.meters'),
        accessor: 'Orders_Metros'
      },
      {
        Header: () => (
          <>
            {t('list_table.length')}
          </>
        ),
        TitleDescription: t('list_table.length'),
        accessor: 'Orders_Longitud'
      },
      {
        Header: () => (
          <>
            {t('list_table.rods')}
            <br />
            {t('list_table.package')}
          </>
        ),
        TitleDescription: t('list_table.rods')+' '+t('list_table.package'),
        accessor: 'Orders_Varillas_Paquete'
      },
      {
        Header: () => (
          <>
            {t('list_table.date')}
            <br />
            {t('list_table.order')}
          </>
        ),
        TitleDescription: t('list_table.date')+' '+t('list_table.order'),
        accessor: 'Orders_Fecha_Pedido',
        Cell: ({ value }) => (value ? formatDate(value) : 'No hay fecha'), // Formatea la fecha a DD/MM/YYYY. Se hace aquí porque, si el formateo ocurre en el useEffect, la tabla trata la fecha como string.  Al ordenar, lo hace por el día y no correctamente por fecha completa. Al formatear directamente en el header, la columna se ordena bien.
        Filter: DateRangeColumnFilter, // Usa el filtro de rango de fechas
        filter: 'dateBetween' // Define el tipo de filtro para rango de fechas
      },
      {
        Header: () => (
          <>
            {t('list_table.date')}
            <br />
            {t('list_table.delivery')}
          </>
        ),
        TitleDescription: t('list_table.date')+' '+t('list_table.delivery'),
        accessor: 'Orders_Fecha_Entrega',
        Cell: ({ value }) => (value ? formatDate(value) : t('list_table.no_date')), // Formatea la fecha a DD/MM/YYYY. Se hace aquí porque, si el formateo ocurre en el useEffect, la tabla trata la fecha como string.  Al ordenar, lo hace por el día y no correctamente por fecha completa. Al formatear directamente en el header, la columna se ordena bien.
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween'
      },
      {
        Header: () => (
          <>
            {t('list_table.pallets')}
            <br />
            {t('list_table.estimated')}
          </>
        ),
        TitleDescription: t('list_table.estimated_pallets'),
        accessor: 'Orders_Cantidad_Estimada'
      },
      {
        Header: () => (
          <>
            {t('list_table.production')}
            <br />
            {t('list_table.foreign')}
          </>
        ),
        TitleDescription: t('list_table.foreign_production'),
        accessor: 'Orders_Produccion_Extranjera'
      }
    ],
    []
  )

  // Adaptar datos para la tabla:
  useEffect(() => {
    if (items) {
      console.log('Entré list table...')

      const itemsData = []

      items.forEach((item) => {
        itemsData.push({
          Orders_Numero_Pedido_Cliente: item.Numero_pedido_Cliente,
          Orders_Numero_Pedido_Technoform: item.lineapedido_numeropedido,
          Orders_Posicion: item.Posicion
            ? new Intl.NumberFormat('de-DE').format(item.Posicion)
            : '',
          Orders_Referencia_Cliente: item.Referencia_Cliente,
          Orders_Referencia_Technoform: item.Referencia_Technoform,
          Orders_Confeccion: item.Confeccion,
          Orders_Material: item.Material
            ? item.Material?.replace(', dry impact resistant', '')
            : '',
          Orders_Metros: item.Metros
            ? new Intl.NumberFormat('de-DE').format(item.Metros)
            : '',
          Orders_Longitud: item.Longitud
            ? new Intl.NumberFormat('de-DE').format(item.Longitud)
            : '',
          Orders_Varillas_Paquete: item.Varillas_Paquete
            ? new Intl.NumberFormat('de-DE').format(item.Varillas_Paquete)
            : '',
          Orders_Fecha_Pedido: item.Fecha_Pedido,
          Orders_Fecha_Entrega: item.Fecha_Entrega,
          Orders_Cantidad_Estimada: item.Cantidad_Estimada,
          Orders_Produccion_Extranjera: item.Produccion_Extranjera ? t('list_table.yes') : t('list_table.no')
        })
      })

      setTableData(itemsData)
    }
  }, [items, i18n.language])

  return (
    <div className="overflow-x-auto">
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-hidden">
          {tableData.length > 0 && <Table data={tableData} columns={columns} />}
        </div>
      </div>
    </div>
  )
}

OrdersCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
