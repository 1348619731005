// PropTypes & HOOKS
import PropTypes from 'prop-types'

// COMPONENTS
import Navbar from './Navbar/Navbar'
import Title from './Title/Title'
import Sidebar from './Sidebar/Sidebar'
import Footer from './Footer/Footer'

export default function Layout({ children }) {
  return (
    <>
      <Navbar />

      <Sidebar />

      <div className="fixed w-screen h-screen">
        <div className="fixed flex flex-col w-full h-full top-0">
          <div className="w-full mt-24 sm:pl-60">
            <Title />
          </div>

          <div id="scrollable-parent" className="w-full grow relative p-4 sm:pl-64 overflow-auto">
            {children}
          </div>
        </div>
      </div>

      {/* <div className="relative flex flex-col sm:ml-60">
        <div className="fixed w-full mt-24">
          <Title />
        </div>

        <div className="p-4 mt-36">{children}</div>
      </div> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
