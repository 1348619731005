import { TitleContext } from 'contexts/TitleContext'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'

export default function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column
  const previousFilter = localStorage.getItem(column.id)
  const { title } = useContext(TitleContext)

  useEffect(() => {
    localStorage.setItem(column.id, filterValue)
  }, [previousFilter, filterValue])

  useEffect(() => {
    if (previousFilter !== 'undefined') {
      setFilter(previousFilter)
    }
  }, [title])

  return (
    <div className="print:hidden">
      <input
        type="text"
        className={`w-full py-1 pr-1 border-2 border-gray-200 focus:outline-none hover:border-gray-300 font-noto font-normal rounded-sm px-1 text-gray-400 text-sm`}
        placeholder="Filtro..."
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setFilter(e.target.value)}
        value={filterValue || ''}
      />
    </div>
  )
}

ColumnFilter.propTypes = {
  column: PropTypes.object.isRequired
}
