import LoginForm from 'forms/login/loginForm'
import PropTypes from 'prop-types'

export default function LoginComp({
  loading,
  error,
  handleSubmitLogin,
  setPwdRecoveryModalOpen
}) {
  return (
    <div className="w-full max-w-lg">
      <LoginForm
        loading={loading}
        handleSubmit={handleSubmitLogin}
        setPwdRecoveryModalOpen={setPwdRecoveryModalOpen}
      />

      <div
        className={`relative px-2 py-1 mt-2 text-red-700 bg-red-100 border border-red-400 rounded-sm ${error ? 'visible' : 'invisible'}`}
        role="alert"
      >
        <strong className="font-notoBlack">Error: </strong>
        <span className="block sm:inline font-noto">
          ¡Usuario o contraseña incorrectos!
        </span>
      </div>
    </div>
  )
}

LoginComp.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  handleSubmitLogin: PropTypes.func.isRequired,
  setPwdRecoveryModalOpen: PropTypes.func
}
