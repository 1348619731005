import flecha from 'assets/img/imageInvert.png'
import { saveAs } from 'file-saver'
import JsPDF, { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next';

export default function OrdersTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos
}) {
  const { t } = useTranslation();
  const rowsEmpty = datos.length === 0
  const exportToExcel = () => {
    // Mapeo de títulos originales a nuevos títulos sin guiones bajos
    const titlesMapping = {
      Orders_Numero_Pedido_Cliente: t('pdf_excel.order_client'),
      Orders_Numero_Pedido_Technoform: t('pdf_excel.order_techno'),
      Orders_Posicion: t('pdf_excel.position'),
      Orders_Referencia_Cliente: t('pdf_excel.reference_client'),
      Orders_Referencia_Technoform: t('pdf_excel.reference_techno'),
      Orders_Confeccion: t('pdf_excel.confection'),
      Orders_Material: t('pdf_excel.material'),
      Orders_Metros: t('pdf_excel.meters'),
      Orders_Longitud: t('pdf_excel.length'),
      Orders_Varillas_Paquete: t('pdf_excel.rods_package'),
      Orders_Fecha_Pedido: t('pdf_excel.order_date'),
      Orders_Fecha_Entrega: t('pdf_excel.delivery_date'),
      Orders_Cantidad_Estimada: t('pdf_excel.estimated_pallets'),
      Orders_Produccion_Extranjera: t('pdf_excel.foreign_production')
    };

    // Crear la hoja de trabajo con los nuevos títulos
    const worksheet = XLSX.utils.json_to_sheet(datos)

    // Reemplazar los títulos en la hoja de trabajo
    const newHeaders = Object.keys(titlesMapping).map(
      (key) => titlesMapping[key]
    )
    worksheet['!cols'] = newHeaders.map(() => ({ wch: 20 })) // Ajustar el ancho de las columnas
    newHeaders.forEach((header, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: index })].v = header // Asignar nuevos títulos
    })

    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
    saveAs(data, 'pedidos_clientes.xlsx')
  }

  // Función para formatear la fecha en DD/MM/YYYY
  const formatDateToSpanish = (dateString) => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0') // Obtener el día con dos dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0') // Obtener el mes con dos dígitos (0-11)
    const year = date.getFullYear() // Obtener el año
    return `${day}/${month}/${year}` // Retornar en formato DD/MM/YYYY
  }

  // Función para exportar todos los usuarios Individuales en un PDF:
  function generateSinglesPDF() {
        const doc = new JsPDF()

    doc.autoTable({
      head: [
        [
          t('table_bar.order_client'),
          t('table_bar.order_techno'),
          t('table_bar.position'),
          t('table_bar.reference_client'),
          t('table_bar.reference_techno'),
          t('table_bar.confection'),
          t('table_bar.material'),
          t('table_bar.meters'),
          t('table_bar.length'),
          t('table_bar.rods_package'),
          t('table_bar.order_date'),
          t('table_bar.delivery_date'),
          t('table_bar.estimated_pallets'),
          t('table_bar.foreign_production')
        ]
      ],
      body: datos.map((dato, i) => [
        dato.Orders_Numero_Pedido_Cliente,
        dato.Orders_Numero_Pedido_Technoform,
        dato.Orders_Posicion,
        dato.Orders_Referencia_Cliente,
        dato.Orders_Referencia_Technoform,
        dato.Orders_Confeccion,
        dato.Orders_Material,
        dato.Orders_Metros,
        dato.Orders_Longitud,
        dato.Orders_Varillas_Paquete,
        dato.Orders_Fecha_Pedido,
        dato.Orders_Fecha_Entrega,
        dato.Orders_Cantidad_Estimada,
        dato.Orders_Produccion_Extranjera
      ]),
      styles: {
        fontSize: 8,
        cellPadding: 1,
        lineWidth: 0.1,
        lineColor: [0, 157, 150]
      },
      columnStyles: {
        0: { cellWidth: 12 }, // 'Nº Pedido Cliente'
        1: { cellWidth: 12 }, // 'Nº Pedido Techno'
        2: { cellWidth: 8 }, // 'Posición'
        3: { cellWidth: 16 }, // 'Referencia Cliente'
        4: { cellWidth: 18 }, // 'Referencia Technoform'
        5: { cellWidth: 10 }, // 'Confección'
        6: { cellWidth: 25 }, // 'Material'
        7: { cellWidth: 12 }, // 'Metros'
        8: { cellWidth: 10 }, // 'Longitud'
        9: { cellWidth: 15 }, // 'Varillas Paquete'
        10: { cellWidth: 16 }, // 'Fecha Pedido'
        11: { cellWidth: 16 }, // 'Fecha Entrega'
        12: { cellWidth: 13 }, // 'Cantidad Estimada'
        13: { cellWidth: 8 } // 'Producción Extranjera'
      },
      margin: { top: 20, right: 10, bottom: 20, left: 10 },
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      headStyles: {
        fillColor: [0, 200, 187],
        lineWidth: 0.1,
        lineColor: [0, 157, 150]
      },
      alternateRowStyles: { fillColor: [221, 221, 221] },
      showHead: 'everyPage',
      showFoot: 'everyPage',
      didDrawPage: (data) => {
        const pageWidth = doc.internal.pageSize.width
        const pageHeight = doc.internal.pageSize.height

        // Footer content (page number)
        doc.setFontSize(10)
        doc.setFont('helvetica', 'bold')
        doc.text(`${data.pageNumber}`, pageWidth / 2, pageHeight - 10, {
          align: 'center'
        })

        // Header content - replicating the logo design
        const headerText1 = 'Mi' // First part "Mi"
        const headerText2 = 'Technoform' // Second part "Technoform"

        const space = 18

        // Set font size and family for the header text
        doc.setFontSize(24)

        // First part of the text ("Mi") with color #0b1f92
        doc.setTextColor(11, 31, 146) // RGB for #0b1f92
        const textWidth1 = doc.getTextWidth(headerText1)
        doc.text(
          headerText1,
          (pageWidth - textWidth1 - doc.getTextWidth(headerText2)) / 2,
          space
        )

        // Second part of the text ("Technoform") with color #00c8bb
        doc.setTextColor(0, 200, 187) // RGB for #00c8bb
        const textWidth2 = doc.getTextWidth(headerText2)
        doc.text(
          headerText2,
          (pageWidth - textWidth2 - doc.getTextWidth(headerText1)) / 2 +
            textWidth1,
          space
        )
      }
    })

    const fechaPDF = new Date()

    doc.save(
      t('table_bar.pdf_filename') +
        fechaPDF.getDate() +
        '_' +
        (fechaPDF.getMonth() + 1) +
        '_' +
        fechaPDF.getFullYear() +
        '.pdf'
    )
  }

  return (
    <div className="flex items-start justify-between px-2 pt-2 text-center bg-white print:hidden">
      <div
        className="flex items-center justify-center float-left space-x-2 text-left cursor-help select-none font-notoBold"
        title={t('table_bar.info_tooltip')}
      >
        <div
          className="text-[#0b1f92] rounded-full w-8 h-8 text-2xl flex justify-center items-center"
          style={{
            border: '3px solid #00c8bb'
          }}
        >
          <span>i</span>
        </div>

        <div className="relative inline-block tooltip text-[#0b1f92] text-sm">
          {t('table_bar.info_title')}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center space-y-2 text-sm font-noto">
        <div className="flex flex-row items-center justify-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold text-white rounded-full bg-[#00c8bb] disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>

          <span>
            Página <strong>{pageIndex + 1}</strong> de{' '}
            <strong>
              {pageOptions.length} {' ('}
              {datos.length} {t('table_bar.register')}{')'}
            </strong>
          </span>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold text-white rounded-full bg-[#00c8bb] disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>

        <select
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
          className="float-right h-10 px-3 outline-none cursor-pointer"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[25, 75, 150, 250, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} {t('table_bar.register')}
            </option>
          ))}
        </select>
      </div>

      <div className="flex items-center justify-center float-left space-x-2 font-bold text-left">
        <button
          type="button"
          className="h-10 px-4 font-notoExtraCondensedBlack text-[#0b1f92] transition-colors duration-150 outline-none bg-white hover:bg-[#00c8bb]"
          onClick={exportToExcel}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
        >
          {t('table_bar.export_excel')}
        </button>

        <button
          type="button"
          className="h-10 px-4 font-notoExtraCondensedBlack text-[#0b1f92] transition-colors duration-150 outline-none bg-white hover:bg-[#00c8bb]"
          onClick={generateSinglesPDF}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          style={{
            border: '3px solid #00c8bb',
            borderRadius: '15px'
          }}
        >
          {t('table_bar.export_pdf')}
        </button>
      </div>
    </div>
  )
}

OrdersTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array
}
