import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table'
import TableBar from './TableBar'

export default function Table({ data, columns, handleClick }) {
  // Estado para guardar los datos filtrados
  const [filteredData, setFilteredData] = useState(data)

  // Filtro personalizado para rango de fechas. Código basado en este ejemplo: https://github.com/TanStack/table/discussions/2453
  function dateBetweenFilterFn(rows, id, filterValues) {
    // Normalize dates to midnight for comparison
    const normalizeDate = (date) => {
      if (!date) return null

      const dateOnly = date.split('T')[0]

      return new Date(dateOnly + 'T00:00:00')
    }

    const sd = filterValues[0] ? normalizeDate(filterValues[0]) : null
    const ed = filterValues[1] ? normalizeDate(filterValues[1]) : null

    // const nsd = normalizeDate(sd)
    // const ned = normalizeDate(ed)

    return rows.filter((r) => {
      // var time =
      //   r.values[id] !== null &&
      //   r.values[id] !== undefined &&
      //   r.values[id] !== ''
      //     ? new Date(r.values[id].split('T')[0])
      //     : new Date(r.values[id])

      var time = new Date(r.values[id])

      let result = true
      if (filterValues.length === 0 || (!sd && !ed)) {
        result = true // No se aplican filtros, devolver todas las filas
      } else if (sd && !ed) {
        // console.log('🚀 ~ returnrows.filter ~ sd:', sd)
        // console.log('🚀 ~ returnrows.filter ~ time:', time)
        // console.log('🚀 ~ returnrows.filter ~ result:', result)
        result = time >= sd // Solo fecha de inicio, filtrar por fechas posteriores o iguales
      } else if (!sd && ed) {
        result = time <= ed // Solo fecha de fin, filtrar por fechas anteriores o iguales
      } else {
        console.log('\n\n\n🚀 ~ returnrows.filter ~ time:', time)
        console.log('🚀 ~ returnrows.filter ~ sd:', sd)
        console.log('🚀 ~ returnrows.filter ~ ed:', ed)
        console.log('🚀 ~ returnrows.filter ~ time >= sd:', time >= sd)
        console.log('🚀 ~ returnrows.filter ~ time <= ed:', time <= ed)
        result = time >= sd && time <= ed // Ambas fechas existen, filtrar entre ellas
      }

      return result
    })
  }

  dateBetweenFilterFn.autoRemove = (val) => !val

  const filterTypes = useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow,
    rows,
    setAllFilters
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageSize: 25 },
      defaultColumn: '150'
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state

  useEffect(() => {
    setFilteredData(rows.map((row) => row.original)) // Actualiza los datos filtrados
  }, [rows])

  return (
    <div className="flex flex-col">
      <div className="inline-block min-w-full align-middle">
        <div className="flex items-center justify-end w-full px-2 pb-2 space-x-2 font-bold text-left">
          <button
            type="button"
            className="h-10 px-4 font-notoBold text-sm text-[#0b1f92] hover:text-[#00c8bb] transition-colors duration-150 outline-none underline decoration-2 decoration-[#00c8bb] hover:decoration-[#0b1f92]"
            onClick={() => {
              setAllFilters([])

              columns.forEach((column) => {
                localStorage.setItem(column.accessor, undefined)
              })
            }}
          >
            Borrar
          </button>
        </div>

        {/* Tabla: */}
        <table {...getTableProps()} className="min-w-full table-fixed">
          {/* Cabecera */}
          <thead className="bg-[#00c8bb] border-2 border-[#00c8bb]">
            {headerGroups.map((headerGroup, index) => (
              <tr
                key={index}
                className="divide-x divide-dashed"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((header, index) => {
                  // La siguiente línea de código sirve para solucionar el siguiente error
                  //  Warning: A props object containing a "key" prop is being spread into JSX:
                  //    let props = {key: someKey, role: ..., onClick: ..., className: ..., children: ...};
                  //    <tr {...props} />
                  //  React keys must be passed directly to JSX without using spread:
                  //    let props = {role: ..., onClick: ..., className: ..., children: ...};
                  //    <tr key={someKey} {...props} />
                  //  Error Component Stack
                  const { key, ...headerProps } = header.getHeaderProps(
                    header.getSortByToggleProps({
                      title: header.TitleDescription
                    })
                  )

                  return (
                    <th
                      key={key}
                      className="text-white hover:bg-[#009d96] font-notoBlack h-20 min-h-20 p-1"
                      {...headerProps}
                    >
                      <div className="flex flex-col justify-between h-full space-y-2">
                        <div
                          className={`${header?.Filter ? 'flex-row' : 'h-full'} flex w-full justify-center items-start text-sm text-center select-none`}
                        >
                          <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                            {header.render('Header')}
                          </span>

                          <div>
                            {header.isSorted ? (
                              header.isSortedDesc ? (
                                <FaLongArrowAltDown
                                  color={'#ffffff'}
                                  size={15}
                                />
                              ) : (
                                <FaLongArrowAltUp color={'#ffffff'} size={15} />
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div>
                          {header.Filter ? header.render('Filter') : null}
                        </div>
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          {/* Cuerpo */}
          <tbody
            className="bg-white divide-y divide-gray-200 border-2 border-[#00c8bb]"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)

              // La siguiente línea de código sirve para solucionar el siguiente error
              //  Warning: A props object containing a "key" prop is being spread into JSX:
              //    let props = {key: someKey, role: ..., onClick: ..., className: ..., children: ...};
              //    <tr {...props} />
              //  React keys must be passed directly to JSX without using spread:
              //    let props = {role: ..., onClick: ..., className: ..., children: ...};
              //    <tr key={someKey} {...props} />
              //  Error Component Stack
              const { key, ...rowProps } = row.getRowProps()

              return (
                <>
                  <tr
                    key={key}
                    {...rowProps}
                    onClick={(e) => handleClick && handleClick(e, row.original)}
                    className={`divide-x divide-dashed divide-[#00c8bb]
                    ${handleClick && 'hover:bg-[#c6f8f6] cursor-pointer'}
										${index % 2 !== 0 && 'bg-[#dddddd]'}`}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          className="py-2 overflow-hidden text-sm font-noto whitespace-nowrap text-ellipsis"
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                </>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
            <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <TableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={filteredData}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
}
