// PropTypes & HOOKS
import PropTypes from 'prop-types'
import React, { createContext, useState, useEffect } from 'react'

export const UserContext = createContext(null)

export const UserContextProvider = ({ children }) => {
  // Hemos creado la variable de entorno 'REACT_APP_ENV' para evitar que se cierre la sesión al recargar o salir de la pantalla en desarrollo.
  // La variable 'localUser' guarda el valor inicial que tendrá la variable 'user' en el useState, y en el useEffect se guarda el valor en el localStorage.
  // En producción, no se realiza este proceso, por lo que la sesión se cerrará al abrir una nueva pestaña, cerrar la actual o recargar la página.
  // La variable de entorno 'REACT_APP_ENV' también está especificada en las variables de entorno de Netlify para el entorno de producción.
  const localUser =
    process.env.REACT_APP_ENV === 'development'
      ? localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
      : null

  const [user, setUser] = useState(localUser)

  useEffect(() => {
    // // Clear session on refresh (F5) or tab close
    // const handleBeforeUnload = () => localStorage.removeItem('user')

    // Save the user to localStorage whenever the `user` changes
    if (user && process.env.REACT_APP_ENV === 'development') {
      localStorage.setItem('user', JSON.stringify(user))
    }

    // // Add event listener for beforeunload (this covers refresh and tab close)
    // window.addEventListener('beforeunload', handleBeforeUnload)

    // // Cleanup function to remove the event listener
    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload)
    // }
  }, [user])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
