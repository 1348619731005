import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
  };

  return (
    <div className="text-sm font-medium text-gray-700 cursor-pointer">
      <span
        onClick={() => changeLanguage('es')}
        className={`cursor-pointer px-1 ${
          i18n.language === 'es' ? 'text-[#00c8bb] font-bold' : 'text-gray-500'
        }`}
      >
        ES
      </span>
      |
      <span
        onClick={() => changeLanguage('pt')}
        className={`cursor-pointer px-1 ${
          i18n.language === 'pt' ? 'text-[#00c8bb] font-bold' : 'text-gray-500'
        }`}
      >
        PT
      </span>
    </div>
  );
};

export default LanguageSwitcher;
