import ListTable from 'components/Orders/ListTable'
import { TitleContext } from 'contexts/TitleContext'
import { UserContext } from 'contexts/UserContext'
import useFetch from 'hooks/useFetch'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { useTranslation } from 'react-i18next';


export default function OrdersList() {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)
  const { user } = useContext(UserContext) // Obtenemos las variables del usuario desde el contexto
  const { t } = useTranslation();

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()

  // CRUD: GET
  useEffect(() => {
    console.log('\n \n \n 🚀 ~ useEffect ~ user ~ OrdersList 1 entré')
    console.log('🚀 ~ useEffect ~ user ~ OrdersList 1:', user)
    if (user) {
      console.log('🚀 ~ useEffect ~ user ~ OrdersList 2:', user)

      const title = {
        name: t('orders_list.orders')
      }
      setTitle(title)

      // Asegúrate de que las variables de usuario están disponibles
      const apiCallGet = {
        url: 'client/orders',
        method: 'POST',
        body: {
          AccessToken: user.AccessToken,
          email: user.email,
          password: user.password
        },
        messageKo: '¡Error cargando Clientes!'
      }
      setApiCallGet(apiCallGet)
    }
  }, [])

  // ############################################################

  // 'handleClick()' para cada item en una lista, al pulsar un item nos llevará a la ruta especificada en el 'setLocation':
  // const handleClick = (e, item) => {
  //   e.preventDefault()
  //   setLocation(
  //     // `/orders/${item.Orders_IdInterno ? item.Orders_IdInterno : 'new'}`
  //     `#`
  //   )
  // }

  // ############################################################

  return getApiCallGet?.data ? (
    <>
      <div
        className={`w-full ${
          getApiCallGet?.data?.length > 0 ? 'block' : 'hidden'
        }`}
      >
        <div className="flex flex-col">
          <ListTable
            items={getApiCallGet.data}
            // handleClick={handleClick}
          />
        </div>
      </div>

      <div
        className={`w-full ${
          getApiCallGet?.data?.length > 0 ? 'hidden' : 'block'
        }`}
      >
        <div className="flex flex-col">
          <div
            className="px-4 py-3 text-red-900 bg-red-100 border-t-4 border-red-500 rounded-b shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="w-6 h-6 mr-4 text-red-500 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">No hay clientes registrados</p>
                <p className="text-sm">
                  ¡Regístra un cliente pulsando el botón{' '}
                  <b>
                    <i>Registrar Cliente</i>
                  </b>
                  !
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <div
          className="flex flex-col-reverse items-center justify-center h-full font-notoBold text-customtechnoformline"
          title="Cargando..."
        >
          ¡Cargando!
          <div className="mt-16 mb-8" style={{ width: '75px', height: '75px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
              <style>
                {`
                  @keyframes zoomInOut {
                    0%, 100%, 5%, 95% {
                      transform: translate(50px, 50px) scale(0);
                    }
                    20%, 80% {
                      transform: translate(10px, 10px) scale(.8);
                    }
                  }
                  
                  @keyframes spin {
                    from {
                      transform: rotate(0);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                  }
                `}
              </style>

              <defs>
                <mask id="Mask">
                  <circle cx="50" cy="50" r="50" fill="#fff"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    style={{
                      animationName: 'zoomInOut',
                      animationDuration: '5.2s',
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear'
                    }}
                  ></circle>
                </mask>
              </defs>

              <g
                style={{
                  animationName: 'spin',
                  animationDuration: '1.3s',
                  transformOrigin: 'center',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear'
                }}
                fill="#00c8bb"
                mask="url(#Mask)"
              >
                <path d="M0 0h50v50H0zM50 50h50v50H50z"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
